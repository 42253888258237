<template>
  <div>
    <v-alert v-if="lock" class="mb-0" outlined type="warning">
      El tiempo de la comisión a <strong>finalizado</strong> y se encuentra
      <strong>bloqueada</strong>. Para desbloquearla por favor contacte con el
      encargado de nómina o gerente de nómina
      <!-- <v-btn @click="modal.unlock = true" dark class="ml-3" color="#fb8c00">
        Aumentar tiempo
      </v-btn> -->
    </v-alert>
    <v-card elevation="0" :disabled="lock">
      <v-row class="mt-0 pt-0 px-5" justify="center">
        <v-col cols="12" class="py-0">
          <h2 class="color-text-main pt-4 pb-2">DETALLES DE LA COMISIÓN</h2>
          <p class="info-text mb-1">
            <strong> Nombre: </strong> {{ infoCommissions.name }}
          </p>
          <p class="info-text mb-1">
            <strong> Fecha: </strong> {{ infoCommissions.date }}
          </p>
          <p class="info-text mb-1">
            <strong> Estado:</strong>
            <v-chip
              label
              dark
              small
              class="ml-1"
              :color="getColorStatus(infoCommissions.status_id)"
            >
              {{ infoCommissions.status.name }}
            </v-chip>
          </p>
          <p class="info-text mb-1">
            <strong> País: </strong> {{ infoCommissions.country }}
          </p>
        </v-col>

        <v-col class="pb-0" cols="12">
          <v-alert :color="alert1.type" outlined>
            La comisión fue creada hace <strong> {{ days }} día(s)</strong>, no
            se ha completado.
          </v-alert>
        </v-col>
        <v-col class="pt-0" cols="12">
          <v-alert v-html="messageAlert" :color="alert1.typeHolidays" outlined>
          </v-alert>
          <p class="info-text-2 mb-1">
            <strong>Progreso de la comisión</strong>
          </p>
        </v-col>

        <v-col cols="12">
          <v-btn-toggle v-model="actionCommission" color="#E95233" group>
            <v-btn outlined value="files"> ARCHIVOS </v-btn>
            <v-btn outlined value="pendingU"> PROGRESO DE LA COMISIÓN </v-btn>
            <v-btn outlined value="history"> HISTORIAL </v-btn>
            <v-btn outlined value="users"> GESTIÓN DE USUARIOS </v-btn>
            <v-btn outlined value="level"> NIVELES DE APROBACIÓN </v-btn>
          </v-btn-toggle>
        </v-col>

        <!-- ARCHIVOS -->
        <template v-if="actionCommission == 'files'">
          <v-col cols="12">
            <v-divider></v-divider>
            <p class="color-text-main text-left mt-4 mb-4">
              <strong> Archivos </strong>
            </p>
            <p class="info-text-2 pb-5">
              En esta sección puedes gestionar los archivos de la comisión,
              adjuntar un primer archivo, adjuntar uno nuevo en caso de ser
              rechazado y a medida de que se vayan aprobando ver el consolidado.
            </p>
            <v-row justify="center" align="center">
              <v-col
                v-if="
                  infoCommissions.status_id != STATUS.active &&
                  infoCommissions.status_id != STATUS.completed &&
                  infoCommissions.status_id != STATUS.cancel &&
                  infoCommissions.status_id != STATUS.rejected
                "
                cols="1"
              >
                <v-btn rounded @click="openModalCancel" color="warning">
                  Anular archivo
                </v-btn>
              </v-col>
              <v-col
                v-if="
                  infoCommissions.status_id ==
                    STATUS.pendingApprovedDirectory ||
                  infoCommissions.status_id == STATUS.approvedDirectory
                "
                class="pt-0"
                cols="12"
              >
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>El archivo esta pendiente por aprobación</b>
                </v-alert>
                <v-btn
                  v-if="infoCommissions.commission_url"
                  color="#E95233"
                  @click="download(infoCommissions.commission_url)"
                  text
                >
                  <u> Descargar comisiones </u>
                </v-btn>
                <v-btn
                  v-if="infoCommissions.variable_url"
                  color="#034f79"
                  @click="download(infoCommissions.variable_url)"
                  text
                >
                  <u> Descargar variables </u>
                </v-btn>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-alert
                  v-if="
                    infoCommissions.status_id == STATUS.rejectedDirectory ||
                    infoCommissions.status_id == STATUS.rejectedManager ||
                    infoCommissions.status_id == STATUS.rejected
                  "
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="warning"
                >
                  <b
                    >Se ha rechazado la comisión, por favor actualice el
                    archivo.</b
                  >
                </v-alert>
              </v-col>
              <template
                v-if="
                  infoCommissions.status_id == STATUS.active ||
                  infoCommissions.status_id == STATUS.cancel ||
                  infoCommissions.status_id == STATUS.rejectedDirectory ||
                  infoCommissions.status_id == STATUS.rejectedManager ||
                  infoCommissions.status_id == STATUS.rejectedCoordinator ||
                  infoCommissions.status_id == STATUS.rejectedAccountManager ||
                  infoCommissions.status_id == STATUS.rejected
                "
              >
                <v-col cols="9">
                  <v-form ref="form2">
                    <v-file-input
                      v-if="show.commission"
                      v-model="form2.file"
                      color="#034f79"
                      outlined
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      label="Archivo de comisones"
                    ></v-file-input>
                    <v-file-input
                      v-if="show.variable"
                      v-model="form2.file2"
                      class="mb-3"
                      color="#034f79"
                      outlined
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      label="Archivo de variables"
                    ></v-file-input>
                  </v-form>
                </v-col>
                <v-col cols="3">
                  <v-layout justify-center>
                    <v-btn
                      color="red"
                      class="mb-4"
                      dark
                      rounded
                      @click="
                        download(
                          'https://autocom.tars.dev/EjemploBaseComisionesVariables.xlsx'
                        )
                      "
                    >
                      Descargar ejemplo
                    </v-btn>
                  </v-layout>
                  <v-layout justify-center>
                    <v-btn
                      :loading="loading.btn"
                      @click="addFileCommission()"
                      color="#034f79"
                      dark
                      rounded
                      class="mb-10"
                    >
                      guardar comisión
                    </v-btn>
                  </v-layout>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </template>
        <!-- USUARIOS PENDIENTES -->
        <template v-if="actionCommission == 'pendingU'">
          <v-col cols="12">
            <v-divider></v-divider>
            <p class="color-text-main text-left mt-4 mb-4">
              <strong> Progreso de la comisión </strong>
            </p>
            <p class="info-text-2 pb-5">
              En esta sección encuentras los usuarios que deben aprobar o
              rechazar la comisión de pendiendo el estado de la misma.
            </p>
            <!-- <v-chart ref="chartpie" class="chart1" :option="option" /> -->
            <v-alert
              v-if="
                infoCommissions.status_id == STATUS.rejectedDirectory ||
                infoCommissions.status_id == STATUS.rejectedManager ||
                infoCommissions.status_id == STATUS.rejected
              "
              icon="mdi-information-outline"
              prominent
              text
              type="warning"
            >
              <b
                >Se ha rechazado la comisión, por favor actualice el archivo.</b
              >
            </v-alert>

            <p class="color-text-main text-left mt-4 mb-4">
              <strong> Barra de progreso </strong>
            </p>

            <v-progress-linear
              color="#2096f4"
              buffer-value="0"
              :value="userProgress"
              stream
              height="18"
              class="mb-8"
            >
              <template v-slot:default="{ value }">
                <strong style="color: #fff; font-size: 14px"
                  >{{ Math.ceil(value) }}%</strong
                >
              </template>
            </v-progress-linear>

            <p class="color-text-main text-left mt-4 mb-4">
              <strong> Listado de usuarios </strong>
            </p>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nombre</th>
                    <th class="text-left">Cédula</th>
                    <th class="text-left">archivo</th>
                    <th class="text-left">Monto</th>
                    <th class="text-left">Comentario</th>
                    <th class="text-left">Aprobación / Rechazo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listPendingU" :key="item.name">
                    <td class="text-left">{{ item.user.name }}</td>
                    <td class="text-left">{{ item.user.document_number }}</td>
                    <td class="text-left">
                      <!-- {{item.comission_actions}} -->
                      <template
                        v-if="
                          item.comission_actions.length > 0 &&
                          item.comission_actions[0].commision_url
                        "
                      >
                        <v-btn
                          small
                          color="#E95233"
                          @click="download(item.commision_url)"
                          text
                        >
                          <u> Descargar comisiones </u>
                        </v-btn>
                      </template>
                      <template v-else>
                        <p class="mb-0 mt-1" style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de comisiones
                          </strong>
                        </p>
                      </template>
                      <template v-if="item.variables_url">
                        <v-btn
                          small
                          color="#034f79"
                          @click="download(item.variables_url)"
                          text
                        >
                          <u> Descargar variables </u>
                        </v-btn>
                      </template>
                      <template v-else>
                        <p class="pl-3" style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de compensación
                            variables
                          </strong>
                        </p>
                      </template>
                    </td>
                    <td class="text-left">
                      <template
                        v-if="
                          item.comission_actions.length > 0 &&
                          item.comission_actions[0].commision_url
                        "
                      >
                        <strong> Comisión: </strong>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <strong
                              v-bind="attrs"
                              v-on="on"
                              style="color: #e95233; font-size: 18px"
                            >
                              {{
                                validateCOP(
                                  item.comission_actions[0].commission_amount
                                )
                              }}
                            </strong>
                          </template>
                          <span>Monto total de la comisión</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <p class="mb-0 mt-1" style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de comisiones
                          </strong>
                        </p>
                      </template>
                      <template
                        v-if="
                          item.comission_actions.length > 0 &&
                          item.comission_actions[0].variables_url
                        "
                      >
                        <strong> Variables: </strong>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <strong
                              v-bind="attrs"
                              v-on="on"
                              style="color: #034f79; font-size: 18px"
                            >
                              {{ validateCOP(item.variable_amount) }}
                            </strong>
                          </template>
                          <span>Monto total de las variables</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <p style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de compensación
                            variables
                          </strong>
                        </p>
                      </template>
                    </td>
                    <td class="text-left">
                      <template
                        v-if="
                          item.comission_actions.length &&
                          item.comission_actions[0].comments
                        "
                      >
                        {{ item.comission_actions[0].comments }}
                      </template>
                      <template v-else>
                        <small class="info-text-2">Sin comentario</small>
                      </template>
                    </td>
                    <td class="text-left">
                      <template
                        v-if="
                          item.comission_actions.length > 0 &&
                          item.comission_actions[0].approval == 1
                        "
                      >
                        <v-icon color="green">mdi-check-circle-outline</v-icon>
                      </template>
                      <template
                        v-else-if="
                          item.comission_actions.length > 0 &&
                          item.comission_actions[0].approval == 0
                        "
                      >
                        <v-icon color="red">mdi-close-circle</v-icon>
                      </template>
                      <template v-else>
                        <small class="info-text-2">Sin respuesta</small>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </template>
        <!-- HISTORIAL -->
        <template v-if="actionCommission == 'history'">
          <p class="info-text-2 pb-5">
            En esta sección puedes identificar en un orden cronológico de la más
            reciente a la más antigua, todas las acciones que se han realizado
            desde que inicio la comisión.
          </p>
          <v-col cols="12">
            <v-divider></v-divider>
            <p class="color-text-main text-left mt-4 mb-4">
              <strong> Historial </strong>
            </p>
            <v-alert
              v-if="listHistory.length == 0"
              icon="mdi-information-outline"
              prominent
              text
              type="info"
            >
              <b>No se encontró información.</b>
            </v-alert>
            <template>
              <v-timeline dense clipped>
                <!-- GRIS #BDBDBD -->
                <v-timeline-item
                  v-for="(data, i) in listHistory"
                  :key="'i' + i"
                  :color="data.color"
                  class="mb-4 text-left"
                  small
                >
                  <v-row justify="space-between">
                    <v-col style="color: #6f6f6f" cols="8">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="white--text ml-0 mr-3"
                            color="#034f79"
                            label
                            small
                          >
                            {{ data.user.document_number }}
                          </v-chip>
                        </template>
                        <span>{{ data.user.name }}</span>
                      </v-tooltip>

                      <strong> Comentario: </strong>
                      <template v-if="data.comments != null">
                        {{ data.comments }}
                      </template>
                      <template v-else>
                        <small> sin comentario </small>
                      </template>

                      <!-- Acciones en la comisión por parte de ese usuario -->
                      <template v-if="data.commision_url">
                        <v-btn
                          small
                          color="#E95233"
                          @click="download(data.commision_url)"
                          text
                        >
                          <u> Descargar comisiones </u>
                        </v-btn>
                        <strong> Monto de la comisión: </strong>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <strong
                              v-bind="attrs"
                              v-on="on"
                              style="color: #e95233; font-size: 18px"
                            >
                              {{ validateCOP(data.commission_amount) }}
                            </strong>
                          </template>
                          <span>Monto total de la comisión</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <p class="mb-0 mt-1" style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de comisiones
                          </strong>
                        </p>
                      </template>
                      <template v-if="data.variables_url">
                        <v-btn
                          small
                          color="#034f79"
                          @click="download(data.variables_url)"
                          text
                        >
                          <u> Descargar variables </u>
                        </v-btn>

                        <strong> Monto de las variables: </strong>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <strong
                              v-bind="attrs"
                              v-on="on"
                              style="color: #034f79; font-size: 18px"
                            >
                              {{ validateCOP(data.variable_amount) }}
                            </strong>
                          </template>
                          <span>Monto total de las variables</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <p style="color: #2096f4">
                          <strong>
                            La comisión no tiene archivo de compensación
                            variables
                          </strong>
                        </p>
                      </template>
                    </v-col>
                    <v-col class="text-right" cols="4">
                      <template
                        v-if="
                          data.commission_users.action_id != ACTIONS.workforce
                        "
                      >
                        <v-tooltip v-if="data.approval == 1" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              label
                              small
                              color="green"
                              class="mr-3"
                              dark
                              >APROBADO</v-chip
                            >
                          </template>
                          <span>Aprobado</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              label
                              small
                              color="#b71c1c"
                              class="mr-3"
                              dark
                            >
                              <strong> NO APROBADO </strong>
                            </v-chip>
                          </template>
                          <span>No aprobado</span>
                        </v-tooltip>
                      </template>
                      <v-chip
                        label
                        dark
                        small
                        class="mr-2"
                        :color="getColorStatus(data.status_id)"
                      >
                        {{ data.status.name }}
                      </v-chip>

                      {{
                        new Date(data.created_at).toISOString().substr(0, 10)
                      }}
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </template>
          </v-col>
        </template>
        <!-- USUARIOS -->
        <template v-if="actionCommission == 'users'">
          <p class="info-text-2 pb-5">
            En esta sección puedes gestionar todos los usuarios que interfieren
            en la comisión, recuerda que si registras o eliminas un usuario
            mientras la comisión esta corriendo esta debe volver hacer aprobada
            por cada uno de los involucrados que se encuentran en esta lista.
          </p>
          <v-col class="py-0" cols="12">
            <v-divider></v-divider>
            <p class="color-text-main text-left mt-4 mb-4">
              <strong> Usuarios </strong>
              <v-btn
                v-if="show.assign"
                class="ml-3"
                rounded
                dark
                color="#034f79"
                @click="modal.assing = true"
              >
                Asignar usuario
              </v-btn>
            </p>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nombre</th>
                      <th class="text-left">Cédula</th>
                      <th class="text-left">email</th>
                      <th class="text-left">Campaña</th>
                      <th class="text-left">Centro de costos</th>
                      <th class="text-left">Estado</th>
                      <th class="text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listUserAssign" :key="item.id">
                      <td>{{ item.user.name }}</td>
                      <td>{{ item.user.document_number }}</td>
                      <td>{{ item.user.email }}</td>
                      <td>
                        <template v-if="item.user.campaign == null"> </template>
                        <template v-else>
                          {{ item.user.campaign.name }}
                        </template>
                      </td>
                      <td>
                        <template v-if="item.user.cost_center == null">
                        </template>
                        <template v-else>
                          {{ item.user.cost_center.name }}</template
                        >
                      </td>
                      <td>
                        <v-chip
                          label
                          dark
                          small
                          :color="getColorStatus(item.status_id)"
                        >
                          {{ item.status.name }}
                        </v-chip>
                      </td>
                      <td>
                        <v-btn
                          @click="openModalInactive(item)"
                          dark
                          color="red"
                          fab
                          icon
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-alert
                v-if="listUserAssign.length == 0"
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
          </v-col>
        </template>
        <!-- NIVELES DE APORBACION -->
        <template v-if="actionCommission == 'level'">
          <v-row class="mt-10" justify="center">
            <v-col cols="8">
              <v-stepper v-model="step" alt-labels>
                <v-stepper-header>
                  <template v-for="data in steps">
                    <v-stepper-step
                      :complete="step > data.order"
                      style="text-align: center"
                      :key="data"
                      :step="data.order"
                    >
                      {{ data.action.name }}
                    </v-stepper-step>

                    <v-divider :key="data"></v-divider>
                  </template>

                  <v-stepper-step
                    :complete="step > lastStep"
                    :step="steps.length"
                  >
                    Completado</v-stepper-step
                  >
                </v-stepper-header>
              </v-stepper>
            </v-col>
          </v-row>
        </template>
      </v-row>
    </v-card>

    <v-dialog v-model="modal.unlock" max-width="400">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <p class="color-text-main pb-0">DESBLOQUEAR COMISIÓN</p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="date"
                label="Nueva fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              v-model="date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                class="mb-5"
                @click="modal.unlock = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="getUnloak()"
                color="#034f79"
                dark
                rounded
                block
              >
                Desbloquear
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modal.assing" max-width="400">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <p class="color-text-main pb-0">Asignar usuarios</p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-form ref="form1">
            <v-row align="center" class="mt-4">
              <v-col class="py-0" cols="12">
                <v-autocomplete
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.action_id"
                  item-value="id"
                  item-text="name"
                  :items="listActions"
                  outlined
                  label="Rol en la comisión *"
                  color="#034f79"
                ></v-autocomplete>
              </v-col>
              <v-col class="py-0" cols="12">
                <v-autocomplete
                  color="#034f79"
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="document_number"
                  return-object
                  item-text="document_number"
                  :items="listUser"
                  outlined
                  label="Lista de usuarios *"
                  placeholder="Seleccione el usuario"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <template v-if="show.manager">
                  <p class="info-text-1 text-left">
                    <strong> Nombre completo: </strong> {{ infoUser.name }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Cédula: </strong> {{ infoUser.cc }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Campaña: </strong> {{ infoUser.campaignAsign }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Centro de costos: </strong>
                    {{ infoUser.constCenter }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Correo electrónico: </strong>
                    {{ infoUser.email }}
                  </p>
                  <p class="info-text-1 text-left">
                    <strong> Rol en el sistema: </strong>
                    {{ infoUser.role}}
                  </p>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                class="mb-5"
                @click="modal.assing = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="addUserCommission()"
                color="#034f79"
                dark
                rounded
                block
              >
                Asignar
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="300" v-model="modal.inactive">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <v-icon x-large> mdi-alert </v-icon>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="pb-0">
            ¿ Estas seguro de inactivar a este usuario de la comisión ?
          </p>
          <p>
            El proceso iniciaría nuevamente al momento de actualizar un usuario
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                dark
                block
                rounded
                :loading="loading.btn"
                class="mb-5"
                @click="modal.inactive = false"
                color="#c1c1c1"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="inactiveUsers()"
                color="#034f79"
                dark
                rounded
                block
              >
                Inactivar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modal.cancel" max-width="500">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <v-icon x-large color="warning"> mdi-alert </v-icon>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="pb-0 mb-2" style="font-size: 16px">
            <strong> ¿ Estás seguro de anular el archivo ? </strong>
          </p>
          <p class="pb-0 mb-0" style="font-size: 16px">
            Una vez se anule el archivo de la comisión, al momento de hacer el
            nuevo cargue del documento, se iniciará con el proceso de aprobación
            o rechazó desde 0
          </p>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="modal.cancel = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="cancel()"
                color="warning"
                dark
                rounded
                block
              >
                Anular archivo
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading.all">
      <v-progress-circular
        color="#e95233"
        width="8"
        indeterminate
        size="100"
      ></v-progress-circular
      ><br />
      <v-layout justify-center>
        <label class="mt-4" style="font-size: 20px">Cargando...</label>
      </v-layout>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import alert from "@/mixins/alert";
import color from "@/mixins/color";
import { STATUS, ACTIONS } from "./../../../utils/util";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  mixins: [alert, color],
  components: {
    VChart,
  },
  data() {
    return {
      ACTIONS: ACTIONS,
      STATUS: STATUS,
      userProgress: 0,
      option: {
        // tooltip: {
        //   trigger: "item",
        // },
        legend: {
          top: "0%",
          left: "center",
        },
        series: [
          {
            name: "Datos",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: "{b}: ({d}%)",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#00A405", "#BD0000", "#DCDCDC"],
            data: [],
          },
        ],
      },
      actionCommission: "files",
      show: {
        files: false,
        pendingU: false,
        history: false,
        users: false,
      },
      id: this.$route.params.commissionId,
      userId: null,
      modal: {
        assing: false,
        inactive: false,
        unlock: false,
        cancel: false,
      },
      lock: false,
      loading: {
        table: false,
        btn: false,
        all: false,
      },
      form: {
        commission_id: null,
        action_id: null,
        document_number: null,
      },
      form2: {
        file: null,
        file2: null,
      },
      document_number: null,
      show: {
        manager: false,
        assign: true,
        commission: false,
        variable: false,
      },
      infoUser: {
        campaignAsign: null,
        constCenter: null,
        cc: null,
        email: null,
        name: null,
        role: null
      },
      infoCommissions: {
        name: "",
        date: "",
        url: "",
        status_id: null,
        status: {
          id: null,
          name: null,
        },
      },
      listUserAssign: [],
      listUser: [],
      listPendingU: [],
      listActions: [],
      listHistory: [],
      alert1: {
        type: "",
      },
      days: null,
      daysHolidays: null,
      messageAlert: "",
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      steps: [],
      step: null,
      lastStep: null,
    };
  },
  watch: {
    actionCommission: function (e) {
      switch (e) {
        case "files":
          break;
        case "pendingU":
          this.getUserCommissionStatus();
          //  this.getUserCommission();
          break;

        case "history":
          this.getHistory();
          break;

        case "users":
          this.getUsers();
          break;
        case "level":
          this.getProgress();
          break;

        default:
          break;
      }
    },
    "$route.params": function (e) {
      this.getDetail();
      this.getHistory();
      this.id = e.commissionId;
    },
    document_number: function (newM) {
      if (newM) {
        this.infoUser = {
          campaignAsign: newM.campaign == null ? " " : newM.campaign.name,
          constCenter: newM.cost_center == null ? " " : newM.cost_center.name,
          cc: newM.document_number == null ? " " : newM.document_number,
          email: newM.email == null ? " " : newM.email,
          name: newM.name == null ? " " : newM.name,
          role: newM.role_users == null && newM.role_users.length == 0 && newM.role_users[0].role == null ? " " :  newM.role_users[0].role.name
        };
        this.form.document_number = newM.document_number;
        this.show.manager = true;
      } else {
        this.show.manager = false;
      }
    },
  },
  methods: {
    validateCOP(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
    inactiveUsers() {
      this.loading.btn = true;
      Api.Workforce()
        .inactiveUser(this.id, this.userId)
        .then((res) => {
          if (res.data.cod == 0) {
            this.getUserCommission();
            this.alert(res.data.message, "Muy bien", "success");
            this.modal.inactive = false;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    openModalInactive(data) {
      this.userId = data.user.id;
      this.modal.inactive = true;
    },
    download(data) {
      window.open(data, "_blank	");
    },
    getUnloak() {
      this.loading.btn = true;
      Api.Workforce()
        .getUnloak(this.id, this.date)
        .then((res) => {
          if (res.data.cod == 0) {
            this.modal.unlock = false;
            this.getDetail();
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.btn = false;
        });
    },
    getDetail() {
      this.loading.all = true;
      Api.Auth()
        .getDetails(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.infoCommissions = res.data.data;
            this.lock = this.infoCommissions.lock_check == 1 ? true : false;
            if (
              this.infoCommissions.status_id == STATUS.active ||
              this.infoCommissions.status_id == STATUS.cancel ||
              this.infoCommissions.status_id == STATUS.rejected
            ) {
              this.show.commission = true;
              this.show.variable = true;
            }
            // else {
            //   console.log(this.infoCommissions)
            //   if (this.infoCommissions.reason_rejections != null) {
            //     switch (this.infoCommissions.reason_rejections.id) {
            //       case 1:
            //         this.show.commission = true;
            //         this.show.variable = false;
            //         break;
            //       case 2:
            //         this.show.commission = false;
            //         this.show.variable = true;
            //         break;
            //       case 3:
            //         this.show.commission = true;
            //         this.show.variable = true;
            //         break;
            //       default:
            //         break;
            //     }
            //   } else {
            //     this.show.commission = false;
            //     this.show.variable = false;
            //   }
            // }
            this.showAlertCommission();
            this.showAlertCommissionHolidays();
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getUserCommission() {
      this.loading.all = true;
      Api.Auth()
        .getUserCommissions(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUserAssign = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    showAlertCommission() {
      var initDate = new Date(this.infoCommissions.created_at).getTime();
      var currentDate = new Date().getTime();
      var diff = currentDate - initDate;
      this.days = (diff / (1000 * 60 * 60 * 24)).toFixed();
      if (this.days <= 2) {
        this.alert1.type = "success";
      } else if (this.days >= 4 || this.days <= 3) {
        this.alert1.type = "warning";
      } else if (this.days >= 5) {
        this.alert1.type = "error";
      }
    },
    showAlertCommissionHolidays() {
      var currentDate = new Date(this.infoCommissions.date);
      var formatYYYYMMDD =
        currentDate.getFullYear() +
        "/" +
        parseInt(currentDate.getMonth() + 1) +
        "/20";
      var initDate = new Date(formatYYYYMMDD).getTime();
      var currentDate = new Date().getTime();
      var diff = initDate - currentDate;
      this.daysHolidays = (diff / (1000 * 60 * 60 * 24)).toFixed();
      if (this.daysHolidays < 0) {
        this.messageAlert =
          "Se <strong> cumplieron los días </strong> para finalizar con la comisón.";
        this.alert1.typeHolidays = "#ff5252";
      } else if (this.daysHolidays >= 0 && this.daysHolidays <= 3) {
        this.messageAlert =
          "Faltan <strong> " +
          this.daysHolidays +
          " día(s)</strong>, para el cumplir con la fecha.";

        this.alert1.typeHolidays = "#fb8c00";
      } else if (this.daysHolidays >= 4) {
        this.messageAlert =
          "Faltan <strong> " +
          this.daysHolidays +
          " día(s)</strong>, para el cumplir con la fecha.";

        this.alert1.typeHolidays = "#4caf50";
      }
    },
    addFileCommission() {
      if (this.$refs.form2.validate()) {
        this.loading.btn = true;
        if (this.form2.file == null && this.form2.file2 == null) {
          this.alert(
            "Debe por lo menos ingresar un archivo de comisiones",
            "Oops...",
            "error"
          );
          return false;
        }
        const formData = new FormData();
        if (this.form2.file != null) {
          formData.append("commission_file", this.form2.file);
        }
        if (this.form2.file2 != null) {
          formData.append("variable_file", this.form2.file2);
        }
        formData.append("commission_id", this.id);

        Api.Workforce()
          .addFileCommission(formData)
          .then((res) => {
            if (res.data.cod == 0) {
              this.getDetail();
              this.getHistory();
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      }
    },
    getActions() {
      this.loading.all = true;
      Api.Auth()
        .getActions()
        .then((res) => {
          if (res.data.cod == 0) {
            this.listActions = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.all = false));
    },
    getProgress() {
      this.loading.table = true;
      Api.Auth()
        .getProgress(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.steps = res.data.data.configCommisions;
            this.step = res.data.data.currentStep + 1;
            this.lastStep = res.data.data.configCommisions.length;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    getUsers() {
      this.loading.table = true;
      Api.Workforce()
        .getUserMRChispa()
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUser = res.data.data;
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    addUserCommission() {
      if (this.$refs.form1.validate()) {
        this.loading.btn = true;
        this.form.commission_id = this.id;

        Api.Workforce()
          .addUserCommission(this.form)
          .then((res) => {
            if (res.data.cod == 0) {
              this.getUserCommission();
              this.modal.assing = false;
              this.$refs.form1.reset();
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      }
    },

    getUserCommissionStatus() {
      Api.Workforce()
        .getStatusUserById(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            var contRes = 0;
            var percentage = 0;
            this.listPendingU = res.data.data;

            for (let i = 0; i < this.listPendingU.length; i++) {
              if (this.listPendingU[i].comission_actions.length >= 1) {
                contRes++;
              }
            }

            percentage = (contRes * 100) / res.data.data.length;

            this.userProgress = percentage;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUserCommissionStatus1() {
      Api.Workforce()
        .getStatusUserById(this.id)
        .then((res) => {
          var countA = 0;
          var countR = 0;
          var countSin = 0;
          if (res.data.cod == 0) {
            this.listPendingU = res.data.data;
            for (let i = 0; i < this.listPendingU.length; i++) {
              if (this.listPendingU[i].comission_actions.length == 0) {
                countSin++;
              }
            }
            this.option.series[0].data = [
              { value: countA, name: "Aprobaron" },
              { value: countR, name: "Rechazaron" },
              { value: countSin, name: "Sin respuesta" },
            ];

            console.log(this.option);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHistory() {
      this.loading.all = true;
      Api.Auth()
        .getHistory(this.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listHistory = res.data.data;
            // this.listPendingU = res.data.data;
            var date1 = null;
            var date2 = (date2 = new Date().toISOString().substr(0, 10));
            for (let i = 0; i < this.listHistory.length; i++) {
              date1 = new Date(this.listHistory[i].created_at)
                .toISOString()
                .substr(0, 10);
              if (date1 == date2) {
                this.listHistory[i].color = "#FF302B";
              } else {
                this.listHistory[i].color = "#BDBDBD";
              }
            }
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    openModalCancel() {
      this.modal.cancel = true;
    },
    cancel() {
      this.loading.btn = true;
      Api.Workforce()
        .getCommissionsCancel(this.infoCommissions.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.alert(res.data.message, "Muy bien", "success");
            this.modal.cancel = false;
            this.getDetail();
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));
    },
  },
  mounted() {
    this.getActions();
    this.getDetail();
    this.getProgress();
    this.getUsers();
    this.getUserCommission();
    this.getHistory();
    this.getUserCommissionStatus();
  },
};
</script>
<style>
.info-text {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}

.info-text-1 {
  color: rgba(0, 0, 0, 0.6);
}

.info-text-2 {
  color: rgba(0, 0, 0, 0.6);
}

.chart1 {
  height: 300px;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
